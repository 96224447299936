<template>
	<div>
		<multiselect
			:placeholder="$t('select')"
			v-model="selected"
			:options="options"
			:class="validateError ? 'box-border-color' : ''"
			@input="handleInput($event, multiple)"
			label="text"
			track-by="value"
			:multiple="multiple"
			:select-label="''"
			:selected-label="''"
			:deselect-label="''"
			:searchable="true"
		>
			<template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
			</template>
			<span slot="noOptions">
                {{ $t("no_options") }}
            </span>
			<span slot="noResult">
                {{ $t("no_result") }}
            </span>
		</multiselect>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	// Helpers
	import translateNSelected from "@/helpers/translateNSelected";
	import handleInput from "@/helpers/handleInput";
	import setSelected from "@/helpers/setSelected";
	
	// Services
	import BuildingService from "@/services/BuildingService";
	
	// Other
	import qs from "qs";
	
	export default {
		props: {
			campus_id: {
				type: Number,
				default: null
			},
			value: {
				default: null,
			},
			multiple: {
				type: Boolean,
				default: false,
			},
			validateError: {
				type: String,
				default: null,
			}
		},
		watch: {
			campus_id: {
				handler: function (newValue, oldValue) {
					if (newValue != oldValue) {
						this.getOptions()
					}
				}
			},
			value: function (newValue) {
				this.selected = this.setSelected(newValue, this.options, this.multiple)
			},
		},
		data() {
			return {
				selected: null,
				options: [],
			}
		},
		created() {
			this.getOptions();
		},
		methods: {
			handleInput: handleInput,
			setSelected: setSelected,
			translateNSelected: translateNSelected,
			getOptions() {
				this.clearOptions()
				if (this.campus_id == null) {
					return;
				}
				let config = {
					params: {
						limit: -1,
						filter: {
							campus_id: this.campus_id
						},
						sort: 'name'
					},
					paramsSerializer: (params) => qs.stringify(params, {encode: false})
				}
				
				BuildingService.getAll(config)
				               .then(response => {
					               const data = response.data.data;
					               data.map(item => {
						               this.options.push({
							               value: item.id,
							               text: item.name,
						               });
					               });
				               })
				               .then(() => {
					               this.selected = this.setSelected(this.value, this.options, this.multiple)
					               if (this.selected == null || this.selected.length == 0) {
						               this.$emit("input", null)
					               }
				               })
			},
			clearOptions() {
				this.options = [];
				this.selected = null;
			}
		}
	}
</script>
