<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('reservation_system')"
                        :isNewButton="checkPermission('reservation_store') && tabIndex == 'reservation_system'"
                        :isFilter="false"
                        :isColumns="false"
                        @new-button-click="createFormShow()"
                />
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('reservation_system')"
                              :isNewButton="checkPermission('reservation_store')"
                              :isFilter="false"
                              :isColumns="false"
                              @new-button-click="createFormShow()"
                />
            </template>

            <b-tabs content-class="py-5 position-relative" class="line-tabs">
                <div class="tabs-bottom-line"></div>
                <b-tab :active="tabIndex=='reservation_system'"
                       @click="tabIndex='reservation_system'"
                       v-if="checkPermission('reservation_index')"
                >
                    <template #title>
                        {{ $t('reservation_system') }}
                    </template>
                    <template>
                        <reservation-system v-if="tabIndex=='reservation_system'" :rsRefresh="rsRefresh"/>
                    </template>
                </b-tab>
                <b-tab :active="tabIndex=='classroom_programs'"
                       @click="tabIndex='classroom_programs'"
                       v-if="checkPermission('reservation_classroomlist')"
                >
                    <template #title>
                        {{ $t('classroom_programs') }}
                    </template>
                    <template>
                        <classroom-schedule v-if="tabIndex=='classroom_programs'"/>
                    </template>
                </b-tab>
                <b-tab :active="tabIndex=='empty_classrooms'"
                       @click="tabIndex='empty_classrooms'"
                       v-if="checkPermission('reservation_emptyclassroom')"
                >
                    <template #title>
                        {{ $t('empty_classrooms') }}
                    </template>
                    <template>
                        <empty-classrooms v-if="tabIndex=='empty_classrooms'" @createFormShow="createFormShow($event)"/>
                    </template>
                </b-tab>
                <b-tab :active="tabIndex=='conflict_informations'"
                       @click="tabIndex='conflict_informations'"
                       v-if="checkPermission('reservation_conflictreport')"
                >
                    <template #title>
                        {{ $t('conflict_informations') }}
                    </template>
                    <template>
                        <conflict-report v-if="tabIndex=='conflict_informations'"/>
                    </template>
                </b-tab>
            </b-tabs>

            <CommonModal ref="createFormModal" size="xxl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear" v-if="checkPermission('reservation_store')">
                <template v-slot:CommonModalTitle>
                    {{ $t('new').toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <create-form @createFormSuccess="createFormSuccess"
                                 :startData="formData"
                                 v-if="formProcess=='create'"
                    />
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>
<script>
    // Template
    import AppLayout from '@/layouts/AppLayout';
    import Header from '@/layouts/AppLayout/Header'
    import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'

    // Components
    import ClassroomSelectbox from '@/components/interactive-fields/ClassroomSelectbox'
    import CommonModal from '@/components/elements/CommonModal'

    // Pages
    import ReservationSystem from './reservationSystem/Index'
    import CreateForm from './reservationSystem/CreateForm'
    import ClassroomSchedule from './classroomSchedule/Index'
    import EmptyClassrooms from './emptyClassrooms/Index'
    import ConflictReport from './conflictReport/Index'

    export default {
        components: {
            AppLayout,
            Header,
            HeaderMobile,
            ClassroomSelectbox,
            CommonModal,
            ReservationSystem,
            CreateForm,
            ClassroomSchedule,
            EmptyClassrooms,
            ConflictReport
        },
        metaInfo() {
            return {
                title: this.$t('reservation_system')
            };
        },
        data() {
            return {
                tabIndex: 'reservation_system',
                filterStatus: true,

                formLoading: false,
                formId: null,
                formData: {},
                formProcess: null,
                rsRefresh: false
            }
        },
        methods: {
            // Clear
            formClear() {
                this.formId = null
                this.formData = {}
                this.formProcess = null
            },

            // Create
            createFormShow(data) {
                this.formId = null
                this.formData = data
                this.formProcess = 'create'
                this.$refs.createFormModal.$refs.commonModal.show()
            },
            createFormSuccess() {
                this.$refs.createFormModal.$refs.commonModal.hide()
                this.rsRefresh = true
                this.formClear()
            }
        }
    }
</script>
