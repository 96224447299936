<template>
    <div>
        <div class="vc-div" :class="validateError ? 'box-border-color':''">
            <v-date-picker mode="time"
                           v-model="selected"
                           :minute-increment="minuteIncrement"
                           :isRange="isRange"
                           is24hr
                           @input="handleInput($event)"
                           :model-config="{type: 'string', mask: this.mask}"
            />
        </div>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                default: null
            },
            mask: {
                type: String,
                default: 'HH:mm'
            },
            minuteIncrement: {
                type: Number,
                default: 1
            },
            validateError: {
                type: String,
                default: ''
            },
            isRange:{
              default:true
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = newValue
            }
        },
        data() {
            return {
                selected: null
            }
        },
        created() {
            this.selected = this.value;
        },
        methods: {
            handleInput(event) {
                this.$emit('input', event);
            }
        }
    }
</script>

<style>
    .vc-div  {
        border: 1px solid #e9ecef;
        border-radius: 8px;
    }
    .vc-div .vc-container {
        width: 100%;
        border: 0px;
        padding: 0px 5px;
    }

    .vc-div .vc-container > div:first-child {
        display: flex;
        width: 100%;
    }

    .vc-div .vc-container .vc-time-picker:first-child {
        border-right: 1px solid #e9ecef
    }

    .vc-div .vc-container .vc-time-picker {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        padding: 15px 0;
    }

    .vc-div .vc-container .vc-time-picker > div:first-child {
        display: none !important;
    }

    .vc-div .vc-container .vc-date {
        display: none !important;
    }

    .vc-div .vc-container .vc-date-time {
        margin-left: 0px;
    }

    .vc-select select {
        background: none !important;
        border: 1px solid #e9ecef !important;
        width: auto !important;
        height: 36px !important;
        color: #495057 !important;
        font-size: 14px !important;
        font-weight: normal !important;
    }
</style>
