<template>
    <div>
        <div class="d-flex justify-content-between bg-gray-50">
            <b-form-checkbox switch size="lg" v-model="eventSwitch">{{ $t("event_switch") }}
                {{
                eventSwitch == true ? $t("event_show") : $t("event_hide")
            }}</b-form-checkbox>
            <b-button variant="primary" @click="printPage"><i class="ri-printer-line"></i> {{ $t("print") }}</b-button>
        </div>
        <loading v-show="loading" />
        <div v-show="!loading">
            <vue-cal :selected-date="startDate" :time-cell-height="30" :time-step="30"
                :disable-views="['years', 'year', 'month', 'day']" :hide-weekdays="[]" :events="timetables"
                :locale="$i18n.locale" :time-from="timeFrom" :time-to="timeTo" @view-change="changeView($event)"
                :on-event-click="showVueCalModal" />
            <CommonModal ref="vueCalModal" size="sm">
                <template v-slot:CommonModalTitle>
                    <i style="font-size: 24px" class="ri-calendar-event-line"></i>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <b-row>
                            <b-col md="12" class="mb-2">{{
                selectedEvent.date
            }}</b-col>
                            <b-col md="12" class="mb-2">{{
                    selectedEvent.title
                }}</b-col>
                            <b-col md="12" class="mb-2">
                                {{ selectedEvent.start_time }} -
                                {{ selectedEvent.end_time }}</b-col>
                            <b-col md="12" v-html="selectedEvent.content"></b-col>
                        </b-row>
                    </div>
                </template>
            </CommonModal>
        </div>
    </div>
</template>
<script>
// Services
import ReservationService from "@/services/ReservationService";

// Components
import Loading from "@/components/elements/Loading";
import CommonModal from "@/components/elements/CommonModal";

// Timetable
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "/src/locales/tr/vuecal.js";

// Other
import moment from "moment";
import qs from "qs";
import tableStyle from "../style";
import logoCode from "../logoCode";

export default {
    name: "ReservationPageClassroomScheduleShowForm",
    components: {
        VueCal,
        Loading,
        CommonModal,
    },
    props: {
        formId: {
            type: Number,
        },
        classroomName: {
            type: String,
        },
    },
    created() {
        this.startDate = moment()
            .startOf("week")
            .add(+1, "days")
            .format("YYYY-MM-DD");
        this.classroomSchedule();
        this.getReservations();
    },
    data() {
        return {
            tableStyle,
            logoCode,
            timetables: [],
            startDate: null,
            timeFrom: 7 * 60,
            timeTo: 24 * 60,
            loading: false,
            selectedEvent: {},
            eventSwitch: true,
            reservations: [],
        };
    },

    watch: {
        eventSwitch: {
            handler() {
                this.classroomSchedule();
                this.getReservations();
            },
        },
    },

    methods: {
        changeView(event) {
            this.startDate = moment(event.startDate).format("YYYY-MM-DD");
            this.classroomSchedule();
            this.getReservations();
        },
        classroomSchedule() {
            const config = {
                classroom_id: this.formId,
                date: this.startDate,
            };
            this.timetables = [];
            this.loading = true;
            ReservationService.classroomSchedule(config)
                .then((response) => {
                    let schedule = response.data.data;
                    let days = Object.values(schedule);

                    if (days.length) {
                        Object.values(schedule).forEach((day) => {
                            if (day.length) {
                                day.forEach((lesson) => {
                                    let title =
                                        lesson.course_code == null
                                            ? this.getLocaleText(lesson, "name")
                                            : lesson.course_code +
                                            " - " +
                                            this.getLocaleText(
                                                lesson,
                                                "name"
                                            );
                                    if (this.eventSwitch == false) {
                                        if (lesson.course_code != null) {
                                            this.timetables.push({
                                                start:
                                                    lesson.date +
                                                    " " +
                                                    lesson.start_time,
                                                end:
                                                    lesson.date +
                                                    " " +
                                                    lesson.end_time,
                                                title: title,
                                                course_code: lesson.course_code,
                                                instructor: lesson.instructor,
                                                content: "",
                                            });
                                        }
                                    } else {
                                        this.timetables.push({
                                            start:
                                                lesson.date +
                                                " " +
                                                lesson.start_time,
                                            end:
                                                lesson.date +
                                                " " +
                                                lesson.end_time,
                                            title: title,
                                            course_code: lesson.course_code,
                                            instructor: lesson.instructor,
                                            content: "",
                                        });
                                    }
                                });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.showErrors(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async getReservations() {
            const config = {
                params: {
                    filter: {
                        date: `${this.startDate},${moment(this.startDate)
                            .add(6, "days")
                            .format("YYYY-MM-DD")}`,
                    },
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };
            const response = await ReservationService.getAll(config);

            if (response.status === 200) {
                this.reservations = response.data.data;
            }
        },
        showVueCalModal(event, e) {
            this.selectedEvent = event;
            moment.locale("tr");
            this.selectedEvent.date = moment(event.start).format("DD-MM-YYYY");
            this.selectedEvent.start_time = moment(event.start).format("LT");
            this.selectedEvent.end_time = moment(event.end).format("LT");
            this.$refs.vueCalModal.$refs.commonModal.show();
            e.stopPropagation();
        },

        printPage() {
            const printEl = document.createElement("div");
            printEl.setAttribute("class", "center");
            const logoDiv = document.createElement("div");
            logoDiv.setAttribute("class", "logo");
            const logoImg = document.createElement("img");
            logoImg.setAttribute("src", this.logoCode);
            logoDiv.appendChild(logoImg);
            printEl.appendChild(logoDiv);

            const titleDiv = document.createElement("div");
            titleDiv.setAttribute("class", "title");
            titleDiv.innerHTML = `T.C. <br> BAHÇEŞEHİR ÜNİVERSİTESİ REKTÖRLÜĞÜ <br> Öğrenci İşleri Başkanlığı`;
            printEl.appendChild(titleDiv);

            const courseDiv = document.createElement("div");
            const title4 = document.createElement("h4");
            title4.innerText = `${this.classroomName} Haftalık Ders Programı`;
            courseDiv.style.marginLeft = "150px";
            courseDiv.style.position = "absolute";
            courseDiv.style.top = "40px";
            courseDiv.style.textAlign = "start";
            courseDiv.appendChild(title4);
            printEl.appendChild(courseDiv);

            const contentDiv = document.createElement("div");
            contentDiv.setAttribute("class", "content");
            const dataTable = document.createElement("table");
            dataTable.style.marginTop = "50px";
            dataTable.style.width = "100%";
            dataTable.style.height = "80%";
            contentDiv.append(dataTable);
            const dataTBody = document.createElement("tbody");
            dataTable.appendChild(dataTBody);

            const headerTr = document.createElement("tr");
            [
                "Saat / Günler",
                "Pazartesi",
                "Salı",
                "Çarşamba",
                "Perşembe",
                "Cuma",
                "Cumartesi",
            ].forEach((i) => {
                const headerTd = document.createElement("td");
                headerTd.classList.add("td-header");
                headerTd.innerText = i;
                headerTr.appendChild(headerTd);
            });
            dataTBody.appendChild(headerTr);

            const sortedArray = [...this.timetables].sort((a, b) =>
                moment(a.start).diff(moment(b.start))
            );

            let startDate
            if (sortedArray[0]?.start) {
                startDate = new Date(sortedArray[0].start);
            } else {
                sortedArray.push({ start: new Date() })
                startDate = new Date()
            }

            const day = startDate.getDay();
            const diff = startDate.getDate() - day + (day == 0 ? -6 : 1);
            startDate.setDate(diff);
            startDate.setHours(8);
            startDate.setMinutes(30);
            const endDate = new Date(sortedArray[0].start);
            endDate.setDate(diff);
            endDate.setHours(22);
            endDate.setMinutes(0);

            while (moment(startDate).isBefore(endDate)) {
                const tr = document.createElement("tr");
                const td0 = document.createElement("td");
                td0.classList.add("td-hour");
                const td1 = document.createElement("td");
                const td2 = document.createElement("td");
                const td3 = document.createElement("td");
                const td4 = document.createElement("td");
                const td5 = document.createElement("td");
                const td6 = document.createElement("td");

                const end = moment(startDate).add(50, "minutes");
                td0.innerText = `${startDate.getHours()}:${startDate.getMinutes()} - ${end.hour()}:${end.minute()}`;
                tr.appendChild(td0);

                const monday = this.weekdayFilter(sortedArray, 1, startDate);
                monday
                    .map((i) => `${i.course_code} ${i.instructor}`)
                    .forEach((a) => {
                        const div = document.createElement("div");
                        div.innerText = a;
                        td1.appendChild(div);
                    });
                tr.appendChild(td1);

                const tuesday = this.weekdayFilter(sortedArray, 2, startDate);
                tuesday
                    .map((i) => `${i.course_code} ${i.instructor}`)
                    .forEach((a) => {
                        const div = document.createElement("div");
                        div.innerText = a;
                        td2.appendChild(div);
                    });
                tr.appendChild(td2);

                const wednesday = this.weekdayFilter(sortedArray, 3, startDate);
                wednesday
                    .map((i) => `${i.course_code} ${i.instructor}`)
                    .forEach((a) => {
                        const div = document.createElement("div");
                        div.innerText = a;
                        td3.appendChild(div);
                    });
                tr.appendChild(td3);

                const thursday = this.weekdayFilter(sortedArray, 4, startDate);
                thursday
                    .map((i) => `${i.course_code} ${i.instructor}`)
                    .forEach((a) => {
                        const div = document.createElement("div");
                        div.innerText = a;
                        td4.appendChild(div);
                    });
                tr.appendChild(td4);

                const friday = this.weekdayFilter(sortedArray, 5, startDate);
                friday
                    .map((i) => `${i.course_code} ${i.instructor}`)
                    .forEach((a) => {
                        const div = document.createElement("div");
                        div.innerText = a;
                        td5.appendChild(div);
                    });
                tr.appendChild(td5);

                const saturday = this.weekdayFilter(sortedArray, 6, startDate);
                saturday
                    .map((i) => `${i.course_code} ${i.instructor}`)
                    .forEach((a) => {
                        const div = document.createElement("div");
                        div.innerText = a;
                        td6.appendChild(div);
                    });
                tr.appendChild(td6);

                const trChild = tr.children;
                for (let index = 1; index < trChild.length; index++) {
                    const child = trChild[index];
                    if (
                        !child.innerText ||
                        child.firstChild?.innerText == " "
                    ) {
                        child.innerText = this.$t("reservation_system");
                    }
                }
                dataTBody.appendChild(tr);

                startDate.setMinutes(startDate.getMinutes() + 60);
            }

            printEl.appendChild(contentDiv);

            const w = window.open(
                "data:text/html," + printEl.innerHTML,
                "_blank",
                "width=800,height=800"
            );
            w.document.body.appendChild(printEl);

            const style = document.createElement("style");
            style.appendChild(document.createTextNode(this.tableStyle));
            w.document.head.appendChild(style);
            w.print();
            w.close();
        },
        weekdayFilter(arr = [], day, startDate) {
            if (!startDate || arr.length < 1) return [];

            const rightDays = arr.filter(
                (a) => moment(a.start).weekday() === day
            );

            const dayDiff = day - 1;
            const innerStartDate = new Date(startDate);
            const innerEndDate = new Date(startDate).setMinutes(
                startDate.getMinutes() + 50
            );

            const result = rightDays.filter((i) => {
                const scheduleStart = moment(innerStartDate).add(
                    "days",
                    dayDiff
                );
                const scheduleEnd = moment(innerEndDate).add("days", dayDiff);

                if (
                    moment(i.start).isSame(scheduleStart) ||
                    moment(i.end).isSame(scheduleEnd)
                ) {
                    return true;
                }
                if (
                    moment(scheduleStart).isBefore(i.start) &&
                    moment(i.start).isBefore(scheduleEnd)
                ) {
                    return true;
                }
                if (
                    moment(scheduleStart).isBefore(i.end) &&
                    moment(i.end).isBefore(scheduleEnd)
                ) {
                    return true;
                }
                if (
                    moment(i.start).isBefore(scheduleStart) &&
                    moment(i.end).isAfter(scheduleEnd)
                ) {
                    return true;
                }
                return false;
            });

            return result;
        },
    },
};
</script>

<style>
.vuecal {
    border: 1px solid #e9ecef;
    box-shadow: none;
}

.vuecal__menu {
    display: none;
}

.vuecal__weekdays-headings,
.vuecal__all-day {
    background: #e9ecef;
}

.vuecal__event {
    background-color: #e9ecef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #cccccc;
}

.vuecal__cell--selected {
    background: none !important;
}

.vuecal__cell--current,
.vuecal__cell--today {
    background: none !important;
}

.vuecal__view-btn--active {
    background: #e9ecef;
    border-bottom: 0;
}

.vuecal__event-title,
.vuecal__event-time,
.vuecal__event-content {
    font-size: 13px;
    font-weight: 500;
}

@media screen and (max-width: 992px) {

    .vuecal__event-title,
    .vuecal__event-time,
    .vuecal__event-content {
        font-size: 10px;
        font-weight: 500;
    }
}
</style>
